import { Buffer } from 'buffer';
import JSZip from 'jszip';
import { offlineData } from '../configs/offlineData';
import { UserTypes } from '../constants/constant';

export const isNullEmptyOrUndefined = (value) => value === null || value === '' || value === undefined || value.length === 0;

export const userData = () => (localStorage.getItem('userData') !== 'undefined' ? JSON.parse(localStorage.getItem('userData'))
  : {
    competitorAdmin: null,
    isOfUserType: null,
    superAdmin: null,
    isSuperAdmin: false,
    isValidUserAccessRole: false,
    units: [],
    userId: '',
    userName: '',
  });

export const isIos = () => userData()?.isOfUserType === UserTypes.IoS;

export const isRu = () => userData()?.isOfUserType === UserTypes.RU;

export const isSu = () => userData()?.isOfUserType === UserTypes.SU;

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const isNullEmptyUndefinedOrZero = (value) => isNullEmptyOrUndefined(value) || value === 0 || value === '0';

export const isNullEmptyUndefinedOrZeroWithDot = (value) => isNullEmptyOrUndefined(value) || value === 0 || value === '0' || value === '0.' || value === '0.0' || value === '.' || value === '.0';

export const userRu = () => (localStorage.getItem('selectedRetailUnit') ? JSON.parse(localStorage.getItem('selectedRetailUnit'))?.ruCode : null);

export const userRuDetails = () => (localStorage.getItem('selectedRetailUnit') ? JSON.parse(localStorage.getItem('selectedRetailUnit')) : null);

export const userRefRu = () => (localStorage.getItem('selectedRetailUnit') ? JSON.parse(localStorage.getItem('selectedRetailUnit'))?.refRuCode
|| JSON.parse(localStorage.getItem('selectedRetailUnit'))?.ruCode : null);

export const isValidUrl = (urlString) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const projectType = () => {
  let prjType;
  if (isIos()) prjType = 0;
  else if (isRu()) prjType = 1;
  else if (isSu()) prjType = 2;
  else prjType = null;
  return prjType;
};

export const userType = () => {
  let prjType;
  if (isIos() || isRu()) prjType = 1;
  else prjType = 2;
  return prjType;
};

export const isLocalHost = () => {
  const isLocal = window.location.hostname === 'localhost';
  return isLocal;
};

export const fetchSelectedHfbOffline = () => offlineData.getItem('selectedHfbs').then((data) => data);

export const fetchSelectedRuOffline = () => offlineData.getItem('selectedRetailUnit').then((data) => data?.ruCode);

export const env = () => {
  let baseUrl = 'https://api.tocompete.ikea.net/api/v1/';
  let cId = 'zyFns1rMZuz5d8nCgoHjG2FyUFoKlWq4';
  let authUrl = 'https://icow.accounts.ingka.com';
  let audience = 'https://api.ingka.ikea.com';
  let logoutUrl = 'https://icow.accounts.ingka.com/oidc/logout?federated';
  if (window.location.hostname.includes('test') || window.location.hostname === 'localhost') {
    baseUrl = 'https://gateway.2comp.nonprod.apaas.inter.ikea.net/test/api/v1/';
    cId = 'BmLlbyQtX3VPnoIdfU8ph0DKqFwxrxwh';
    authUrl = 'https://testicow.accounts.ingka.com';
    audience = 'https://api.ingka.ppe.ikeadt.com';
    logoutUrl = 'https://ingka-icow-test.eu.auth0.com/oidc/logout?federated';
  } else if (window.location.hostname.includes('qa')) {
    baseUrl = 'https://gateway.2comp.nonprod.apaas.inter.ikea.net/qa/api/v1/';
    cId = 'BmLlbyQtX3VPnoIdfU8ph0DKqFwxrxwh';
    authUrl = 'https://testicow.accounts.ingka.com';
    audience = 'https://api.ingka.ppe.ikeadt.com';
    logoutUrl = 'https://ingka-icow-test.eu.auth0.com/oidc/logout?federated';
  } else if (window.location.hostname.includes('dev')) {
    cId = 'BmLlbyQtX3VPnoIdfU8ph0DKqFwxrxwh';
    baseUrl = 'https://gateway.2comp.nonprod.apaas.inter.ikea.net/dev/api/v1/';
    authUrl = 'https://testicow.accounts.ingka.com';
    audience = 'https://api.ingka.ppe.ikeadt.com';
    logoutUrl = 'https://ingka-icow-test.eu.auth0.com/oidc/logout?federated';
  } else if (window.location.hostname.includes('ppe')) {
    cId = 'zyFns1rMZuz5d8nCgoHjG2FyUFoKlWq4';
    baseUrl = 'https://gateway.2comp.nonprod.apaas.inter.ikea.net/ppe/api/v1/';
    authUrl = 'https://icow.accounts.ingka.com';
    audience = 'https://api.ingka.ikea.com';
    logoutUrl = 'https://icow.accounts.ingka.com/oidc/logout?federated';
  }
  return {
    baseUrl, cId, authUrl, audience, logoutUrl,
  };
};

export const notificationUrls = (userID = '') => {
  const testBaseUrl = 'https://api-dev.orangeflower-091a7ddd.westeurope.azurecontainerapps.io/';
  const prodBaseUrl = 'https://notification-api.inter.ikea.net/';
  let notificationHighlight = `${prodBaseUrl}/sdf/api/release-content/v1/release-content/GetNotificationHighlight?userId=${userID || userData()?.userId}&appCode=ToCompete`;
  let popupDisplay = `${prodBaseUrl}/sdf/api/release-content/v1/release-content/GetPopupNotification?userId=${userID || userData()?.userId}&appCode=ToCompete`;
  let updateHighlight = `${prodBaseUrl}sdf/api/release-content/v1/release-content/UpdateNotification?userId=${userID || userData()?.userId}&appCode=ToCompete&type=widget`;
  let notificationSideBar = `${prodBaseUrl}sdf/api/release-note/v1/release-notes?appCode=ToCompete&userId=${userID || userData()?.userId}&type=widget`;
  let notificationPopup = `${prodBaseUrl}sdf/api/release-note/v1/release-notes?appCode=ToCompete&userId=${userID || userData()?.userId}&type=popup`;
  let notificationPopupClose = `${prodBaseUrl}sdf/api/release-content/v1/release-content/ClosePopup?appCode=ToCompete&userId=${userID || userData()?.userId}`;
  let notificationDismiss = `${prodBaseUrl}/sdf/api/release-content/v1/release-content/DismissNotification?appCode=ToCompete&userId=${userID || userData()?.userId}&rcno=`;
  let notifMediaToken = `${prodBaseUrl}/sdf/api/release-content/v1/blobstorage/OAuthToken`;
  let notifCenterToken = `${prodBaseUrl}/sdf/api/release-content/v1/notification-center/GetToken?appName=ToCompete`;
  let notifCenterUrl = 'https://notification-center.inter.ikea.net/?appCode=';
  if (window.location.hostname.includes('test')
  || window.location.hostname === 'localhost'
  || window.location.hostname.includes('qa')
  || window.location.hostname.includes('dev')
  ) {
    notificationHighlight = `${testBaseUrl}/sdf/api/release-content/v1/release-content/GetNotificationHighlight?userId=${userID || userData()?.userId}&appCode=ToCompete`;
    popupDisplay = `${testBaseUrl}/sdf/api/release-content/v1/release-content/GetPopupNotification?userId=${userID || userData()?.userId}&appCode=ToCompete`;
    updateHighlight = `${testBaseUrl}sdf/api/release-content/v1/release-content/UpdateNotification?userId=${userID || userData()?.userId}&appCode=ToCompete&type=widget`;
    notificationSideBar = `${testBaseUrl}sdf/api/release-note/v1/release-notes?appCode=ToCompete&userId=${userID || userData()?.userId}&type=widget`;
    notificationPopup = `${testBaseUrl}sdf/api/release-note/v1/release-notes?appCode=ToCompete&userId=${userID || userData()?.userId}&type=popup`;
    notificationPopupClose = `${testBaseUrl}sdf/api/release-content/v1/release-content/ClosePopup?appCode=ToCompete&userId=${userID || userData()?.userId}`;
    notificationDismiss = `${testBaseUrl}/sdf/api/release-content/v1/release-content/DismissNotification?appCode=ToCompete&userId=${userID || userData()?.userId}&rcno=`;
    notifMediaToken = `${testBaseUrl}/sdf/api/release-content/v1/blobstorage/OAuthToken`;
    notifCenterToken = `${testBaseUrl}/sdf/api/release-content/v1/notification-center/GetToken?appName=ToCompete`;
    notifCenterUrl = 'https://notification-center-dev.orangeflower-091a7ddd.westeurope.azurecontainerapps.io/?appCode=';
  }
  return {
    notificationHighlight,
    popupDisplay,
    updateHighlight,
    notificationSideBar,
    notificationPopup,
    notificationPopupClose,
    notificationDismiss,
    notifMediaToken,
    notifCenterToken,
    notifCenterUrl,
    prodBaseUrl,
  };
};

export const convertUnit = (type, value) => {
  if (type === 'kg' || type === 'l' || type === 'pc' || type === 'lb') {
    return value;
  } if (type === 'oz') {
    return value / 16; // ounce to pound
  } if (type === 'cl') {
    return value / 100; // cl to liter
  } if (type === 'gallon') {
    return value * 3.785; // gallon to liter
  } if (type === 'fl.oz') {
    return value / 33.814; // fl ounce to liter
  }
  return value / 1000; // g || ml to kg or l
};

export const convertToBase64 = (val) => {
  if (!isNullEmptyOrUndefined(val)) {
    const stringValue = typeof val === 'object' ? JSON.stringify(val) : val;
    return Buffer.from(stringValue).toString('base64');
  }
  return null;
};

export const removeLogoutOfflineData = () => {
  localStorage.clear();
  sessionStorage.clear();
  offlineData.removeItem('projectData');
  offlineData.removeItem('selectedHfbs');
  offlineData.removeItem('selectedRetailUnit');
  offlineData.removeItem('userDetails');
  offlineData.removeItem('userRetailUnits');
  offlineData.removeItem('competitorsList');
};

export const setLogoutOfflineData = () => {
  localStorage.setItem('loggedInOnce', 'true');
  localStorage.setItem('loggedOut', 'true');
};

export const compressJson = (data) => new Promise((resolve, reject) => {
  const zip = new JSZip();
  zip.file('data.json', JSON.stringify(data));
  // Generate the zip file
  zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: {
      level: 9,
    },
  })
    .then((content) => resolve(content))
    .catch((err) => reject(err));
});

export const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});
