/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Modal, {
  ModalBody, ModalHeader, Theatre,
} from '@ingka/modal';
import Carousel from '@ingka/carousel';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/loading/dist/style.css';
import '@ingka/zoom-image/dist/style.css';
import Text from '@ingka/text';
import IcItems from '@ingka/ssr-icon/paths/copy';
import { Table, ConfigProvider } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ZoomImage from '@ingka/zoom-image';
import './UnitList.css';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '../../../../common/services/translationService.js';
import { isNullEmptyOrUndefined, userData, userType } from '../../../../common/services/commonService.js';
import { useAppDispatch } from '../../../../store/hooks';
import useCallApi from '../../../../common/services/apiService.js';
import RenderEmpty from '../../../../common/components/renderEmpty/RenderEmpty';
import { setLoader, setToast } from '../../../../store/reducers/create-proj-reducer';
import { appRoutes } from '../../../../common/constants/constant';

interface UnitListProps {
  headerHeight: number;
  searchResults: any[];
  isLoading: boolean;
  handleTableChange: (pagination: any, filters: any, sorter: any, extra: any) => void;
}

export interface CustomNews {
  key: React.Key,
  customUnitId:number,
  customUnitName:string,
  customUnitNo: string,
  customUnitType:string,
  imageCount: number,
  paNo: string,
  unitCurrency: string,
  unitRsp:number,
  unitRspDisplay:string
}

export const UnitList = ({
  headerHeight, searchResults, handleTableChange,
}: UnitListProps) => {
  const emptyText = useTranslate('find.unit.searchEmptyText');
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [imageToken, setImageToken] = useState<any>();
  const dispatch = useAppDispatch();
  const { post } = useCallApi();
  const navigate = useNavigate();

  const renderTableTitle = (title: string, dataIndex: string) => (

    <div>{title}</div>
  );

  const renderImage = (text: any) => {
    const isText = !isNullEmptyOrUndefined(text);
    return (
      isText ? (
        <div className={`table-number ${text !== 0 ? 'fcSearchResultsImage' : ''}`}>
          {text}
        </div>
      ) : (
        <div className="table-number">
          {text || 'N/A'}
        </div>
      )
    );
  };

  const onCloseItemModal = () => {
    setOpenImageModal(false);
  };

  const carouselProps = {
    id: 'fcItemDetailsCarousel',
    hasNoControls: false,
    prefix: '',
    ariaLabelLeftBtn: 'See previous items',
    ariaLabelRightBtn: 'See next items',
    isSlideShow: true,
  };

  const fetchUnit = (record: any) => {
    dispatch(setLoader(true));
    const payload = {
      CustomUnitId: record.customUnitId,
      UserId: userData()?.userId,
      UserType: userType(),
    };

    post('get-custom-unit-details', payload, (data: any) => {
      navigate(appRoutes.unitDetails, {
        state: data,
      });
      dispatch(setLoader(false));
    }, () => {
      dispatch(setToast({ openToast: true, toastLabel: 'find.unit.failedToFetch' }));
      dispatch(setLoader(false));
    });
  };

  const getImages = (record: any) => {
    if (isNullEmptyOrUndefined(record.photos) || record.photos === 0) {
      fetchUnit(record);
    } else {
      dispatch(setLoader(true));
      const payload = {
        ComparisonId: record.comparisonProjectId,
        ItemNo: record.itemNo,
        ProjectType: record.projectType,
        ItemType: record.itemType,
      };
      post('get-photos', payload, (data: any) => {
        setSelectedItem(data);
        setOpenImageModal(true);
        dispatch(setLoader(false));
      }, () => {
        dispatch(setLoader(false));
      });
    }
  };

  const columns: ColumnsType<CustomNews> = [
    {
      key: 1,
      title: renderTableTitle('PA', 'paNo'),
      dataIndex: 'paNo',
      sorter: false,
      width: '3%',
      render: (text: any) => <div className="table-number">{text || 'N/A'}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 2,
      title: renderTableTitle('Item #', 'customUnitNo'),
      dataIndex: 'customUnitNo',
      sorter: false,
      width: '9%',
      render: (text: any) => <div className={`${!isNullEmptyOrUndefined(text) ? 'itemNo-common-design' : ''}`}>{text || 'N/A'}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 3,
      title: renderTableTitle('Item type', 'customUnitType'),
      dataIndex: 'customUnitType',
      sorter: false,
      width: '5%',
      render: (text: any) => <div>{text || 'N/A'}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 4,
      title: renderTableTitle('Item name', 'customUnitName'),
      dataIndex: 'customUnitName',
      sorter: false,
      width: '16%',
      render: (text: any) => <div>{text || 'N/A'}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 5,
      title: renderTableTitle(`${useTranslate('tab.items.image')}`, 'imageCount'),
      className: 'table-number',
      dataIndex: 'imageCount',
      sorter: false,
      width: '5%',
      render: (text: any) => renderImage(text),
      // onCell: (record) => ({
      //   onClick: () => getImages(record),
      // }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 5,
      title: renderTableTitle('Price', 'unitRspDisplay'),
      dataIndex: 'unitRspDisplay',
      sorter: false,
      width: '8%',
      className: 'table-number',
      render: (text: any) => <div className="table-number">{text || 'N/A'}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
  ];

  return (
    <div className="searchResultsWrapper" style={{ height: `calc(100vh - ${headerHeight}px)` }}>
      <ConfigProvider renderEmpty={() => (
        <RenderEmpty
          empText={emptyText}
          icon={IcItems}
          subText
        />
      )}
      >
        <Table
          className={`table-striped-rows fcTable
           ${searchResults.length > 0 ? '' : 'fcBodyOverflow'}`}
          columns={columns}
          dataSource={searchResults}
          pagination={false}
          size="small"
          rowKey={(record: any) => record?.itemNo}
          onChange={handleTableChange}
          showHeader={searchResults?.length > 0}
          scroll={searchResults?.length > 0 ? { y: 'calc(100vh - 370px)' } : { y: '' }}
          footer={undefined}
          onRow={(record) => ({
            onClick: () => fetchUnit(record), // click row
          })}
        />
        <Modal
          visible={openImageModal}
          handleCloseBtn={() => onCloseItemModal()}
        >
          <Theatre
            aria-labelledby="fcImageDetailsModal"
            className="fcImageDetails-theatre"
            header={(
              <ModalHeader
                className="defaultFont"
                titleId="fcImageDetailsModal"
                title="Images"
                floating={false}
              />
          )}
          >
            <ModalBody>
              <div className="fcImgeContainer">
                <div className="fcIkeaImage">
                  <Text className="fcImageTitleText">IKEA product images</Text>
                  <Carousel {...carouselProps}>
                    {selectedItem?.ikeaPhotosList?.map(
                      (item:any) => (
                        <ZoomImage
                          src={item?.imagePath}
                        >
                          <img
                            key={item?.imagePath}
                            className="fcItemDetails-item-image"
                            src={item?.imagePath}
                            alt=""
                          />
                        </ZoomImage>
                      ),
                    )}
                  </Carousel>
                </div>
                <div className="fcCompImage">
                  <Text className="fcImageTitleText">Competitor product images</Text>
                  <Carousel {...carouselProps}>
                    {selectedItem?.competitorPhotosList?.map(
                      (item:any) => (
                        <ZoomImage
                          src={item?.imagePath.concat(imageToken?.sasToken)}
                        >
                          <img
                            key={item?.imagePath}
                            className="fcItemDetails-item-image"
                            src={item?.imagePath.concat(imageToken?.sasToken)}
                            alt=""
                          />
                        </ZoomImage>
                      ),
                    )}
                  </Carousel>
                </div>
              </div>
            </ModalBody>
          </Theatre>
        </Modal>
      </ConfigProvider>
    </div>
  );
};
