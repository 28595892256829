/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/carousel/dist/style.css';
import '@ingka/tabs/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/tooltip/dist/style.css';
import Button from '@ingka/button';
import IcDetails from '@ingka/ssr-icon/paths/text';
import IcItems from '@ingka/ssr-icon/paths/copy';
import IcPublish from '@ingka/ssr-icon/paths/arrow-up-from-base';
import { useNavigate } from 'react-router-dom';
import './UnitTopNavigation.css';
import _ from 'lodash';
import { useTranslate } from '../../../../common/services/translationService.js';
import { appRoutes } from '../../../../common/constants/constant';
import UnitDetails from '../unitDetails/UnitDetails';
import ItemsImport from '../ItemsImport/itemsImport';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import AppBarIcons from '../../../../common/components/appBarIcons/AppBarIcons';
import CloseModal from '../../../../common/components/closeModal/CloseModal';
import CustomUnitOverview from '../overviewPopup/CustomUnitOverview';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import { setUnitSubmitValid, createUnitFullReset } from '../../../../store/reducers/create-unit-reducer';

const Icons = ({
  isSubmitEnabled,
  projectcloseClick, onSubmitClick,
}: {
  isSubmitEnabled: boolean,
  projectcloseClick: () => void,
  onSubmitClick: () => void,
}) => (
  <div className="iconsWrapper">
    <Button
      className="actionBtn"
      text={useTranslate('common.text.submit')}
      type="primary"
      ssrIcon={IcPublish}
      disabled={isSubmitEnabled !== true}
      onClick={onSubmitClick}
      small
    />
    <AppBarIcons closeFn={projectcloseClick} />
  </div>
);

const UnitTopNavigation = () => {
  const loader = useAppSelector((state) => state.createProjReducer.isLoading);
  const submitValid = useAppSelector((state) => state?.customUnitReducer?.submitValid);
  const nameUniqueValid = useAppSelector((state) => state?.customUnitReducer?.unitNameValid);
  const customUnitData = useAppSelector((state) => state?.customUnitReducer?.createUnitData);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openPrompt, setOpenPrompt] = useState(false);
  const [onenPreview, setOpenPreview] = useState(false);
  const initialUnitValue = {
    detailsTab: {
      Name: '', Hfb: '', Pra: '', Pa: '', PiaLink: '', Comments: '', Images: [],
    },
    itemsTab: { importedItems: [] },
  };

  const isItemsValid = (items: any) => items?.every((item: any) => item?.isValid === true);

  useEffect(() => {
    const { detailsTab, itemsTab } = _.cloneDeep(customUnitData);
    const {
      Hfb, Pra, Pa, unitState, roc, priceClass, Name,
    } = detailsTab || {};
    const { importedItems } = itemsTab || {};

    if (!isNullEmptyOrUndefined(Hfb)
    && !isNullEmptyOrUndefined(Pra)
    && !isNullEmptyOrUndefined(Pa)
    && !isNullEmptyOrUndefined(unitState)
    && !isNullEmptyOrUndefined(roc)
    && !isNullEmptyOrUndefined(priceClass)
    && !isNullEmptyOrUndefined(Name)
    && importedItems?.length > 0
    && isItemsValid(importedItems)) {
      dispatch(setUnitSubmitValid(true));
    } else {
      dispatch(setUnitSubmitValid(false));
    }
  }, [customUnitData]);

  const redirectDashboard = () => {
    dispatch(createUnitFullReset());
    navigate(
      appRoutes.dashboard,
      {
        state: {
          dashboardFilters: true,
        },
      },
    );
  };

  const compareArray = (arr1: [], arr2: any) => {
    const isEqual = arr1?.length === arr2?.length
      && arr1?.every((value, index) => value === arr2[index]);
    return isEqual;
  };

  const compareArraySpecific = (arr1: [], arr2: any[], val:string) => {
    const isEqual = arr1?.length === arr2?.length
      && arr1?.every((value : any, index) => value[val] === arr2[index][val]);
    return isEqual;
  };

  const compareUnits = () => {
    let isequal = true;
    isequal = JSON.stringify(customUnitData?.detailsTab) === JSON.stringify(initialUnitValue.detailsTab);
    if (isequal === true) {
      isequal = compareArray(customUnitData.itemsTab.importedItems, initialUnitValue.itemsTab.importedItems);
    }
    return isequal;
  };
  const tabs = [
    <Tab
      key="tab-1"
      text={useTranslate('tab.details')}
      tabPanelId="tab_1"
      ssrIcon={IcDetails}
    />,
    <Tab
      key="tab-2"
      text={useTranslate('tab.items')}
      active
      tabPanelId="tab_2"
      ssrIcon={IcItems}
    />,
  ];

  const tabPanels = [
    <TabPanel
      className="utnTabsPanel"
      key="tab-1"
      tabPanelId="tab_1"
    >
      <UnitDetails />
    </TabPanel>,
    <TabPanel
      className="utnTabsPanel"
      key="tab-2"
      tabPanelId="tab_2"
    >
      <ItemsImport />
    </TabPanel>,
  ];

  const onUnitSubmit = () => {
    setOpenPreview(true);
  };

  const handleCloseModal = (type?: number) => {
    if (type === 2) {
      redirectDashboard();
    }
    setOpenPrompt(false);
  };

  const handleUnitClose = () => {
    if (compareUnits() === false) {
      setOpenPrompt(true);
    } else {
      redirectDashboard();
    }
  };

  return (

    <div className="parent" style={{ pointerEvents: loader ? 'none' : 'all' }}>
      <Tabs className="tabsContainer" tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_1" />
      <Icons
        isSubmitEnabled={(submitValid && nameUniqueValid.status)}
        projectcloseClick={handleUnitClose}
        onSubmitClick={onUnitSubmit}
      />
      <CloseModal
        modelOpen={openPrompt}
        closeModal={handleCloseModal}
        saveValid
        closeText={useTranslate('common.text.cancel')}
        saveText={useTranslate('common.text.continue')}
        title={useTranslate('customUnit.items.closePopTitle')}
        subText={useTranslate('customUnit.items.closePopSub')}
      />

      <CustomUnitOverview
        open={onenPreview}
        onClose={() => setOpenPreview(false)}
      />

    </div>
  );
};

export default UnitTopNavigation;
