/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Text from '@ingka/text';
import { isNullEmptyOrUndefined } from '../../../common/services/commonService.js';
import CustomNews from '../createUnit/customNews/CustomNews';
import './ViewUnitDetails.css';
import { appRoutes } from '../../../common/constants/constant';
import AppBarIcons from '../../../common/components/appBarIcons/AppBarIcons';

const ViewUnitDetails = () => {
  const location: any = useLocation();
  const [unitData, setUnitdata] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const data = location?.state;
    if (!isNullEmptyOrUndefined(data)) {
      const detailsTab = {
        Name: data?.customUnitName,
        Hfb: data?.hfbNo,
        Pra: data?.praNo,
        Pa: data?.paNo,
        unitState: data?.unitState,
        roc: data?.roc,
        priceClass: data?.priceClass,
        PiaLink: data?.unitUrl,
        Comments: data?.comment,
        Images: data?.unitImageUrl,
      };
      const itemsTab = {
        importedItems: data?.customUnitItemsDetails,
      };
      const generic = {
        unitNo: data?.customUnitNo,
        sellingPrice: data?.unitRsp,
        currency: data?.unitCurrency,
        unitId: data?.customUnitId,
        unitType: data?.customUnitType,
        canEdit: data?.canEdit,
      };
      setUnitdata({
        detailsTab,
        itemsTab,
        generic,
      });
    }
  }, []);

  const handleCloseModal = () => {
    navigate(
      appRoutes.viewUnit,
      {
        state: {
          isVuDetails: true,
        },
      },
    );
  };

  return (
    <div className="vudParent">
      {!isNullEmptyOrUndefined(unitData) ? (
        <>
          <header className="headerContainer">
            <Text className="icPageTitle">Custom News Details</Text>
            <div className="iconsWrapper">
              <AppBarIcons closeFn={handleCloseModal} />
            </div>
          </header>
          <CustomNews
            detailsTab={unitData?.detailsTab}
            itemsTab={unitData?.itemsTab}
            generic={unitData?.generic}
          />
        </>
      ) : null}
    </div>
  );
};

export default ViewUnitDetails;
