/* eslint-disable @typescript-eslint/no-explicit-any */
import Text from '@ingka/text';
import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import Accordion, { AccordionItem } from '@ingka/accordion';
import Button from '@ingka/button';
import ChildItemsTable from '../childItemsTable/ChildItemsTable';
import { useTranslate } from '../../../../common/services/translationService.js';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import { offlineData } from '../../../../common/configs/offlineData';
import './CustomNews.css';
import useCallApi from '../../../../common/services/apiService.js';

const RenderImages = ({ images, preview }: any) => {
  const [imgList, setImgList] = useState<any>([]);
  const [imageToken, setImageToken] = useState<any>();
  const { get } = useCallApi();

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  useEffect(() => {
    getSasToken();
    const fetchImages = async () => {
      const fetchList = await Promise.all(
        images.map(async (image: any) => {
          const val: any = await offlineData.getItem(image);
          return val instanceof Blob ? val : new Blob([val.originFileObj]);
        }),
      );

      const imgPromises = fetchList.map((file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      }));

      const imgResults = await Promise.all(imgPromises);
      setImgList(imgResults);
    };

    if (preview === true) {
      fetchImages();
    } else {
      setImgList(images);
    }
  }, [images]);

  return (
    <div className="cuoImgWrapper">
      {imgList.map((img: any, index: number) => (
        <img className="cuoImg" src={preview !== true ? img.concat(imageToken?.sasToken) : img} alt={`Img${index.toString()}`} key={`Img${index.toString()}`} />
      ))}
    </div>
  );
};

interface CustomNewsProps {
  detailsTab: any;
  itemsTab: any;
  generic?: any;
  isPreview?: boolean;
  saveUnit?: () => any;
}

const CustomNews = ({
  detailsTab, itemsTab, generic, isPreview, saveUnit,
}: CustomNewsProps) => {
  const cuoNumber = useTranslate('customUnit.overview.cuoNumber');
  const cuoHfb = useTranslate('customUnit.overview.hfb');
  const cuoPra = useTranslate('customUnit.overview.pra');
  const cuoPa = useTranslate('customUnit.overview.pa');
  const cuoStatus = useTranslate('customUnit.overview.status');
  const cuoROC = useTranslate('customUnit.overview.roc');
  const cuoPriceClass = useTranslate('customUnit.overview.priceClass');
  const cuoPrice = useTranslate('customUnit.overview.price');
  const cuoReferenceUrl = useTranslate('customUnit.overview.referenceUrl');
  const cuoComments = useTranslate('customUnit.overview.comments');
  const cuoRefImg = useTranslate('customUnit.overview.refImg');
  const cuoItems = useTranslate('customUnit.overview.items');
  const cuoSubmit = useTranslate('customUnit.overview.submit');

  const getFullUrl = (url: string) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  };

  const totalPrice = () => {
    let total = 0;
    itemsTab?.importedItems?.forEach((item: any) => { total += item.totalPricePerQuantity; });
    return total;
  };

  return (
    <div className="cuoContainer">
      <Text className="cuoTitle">{detailsTab?.Name}</Text>
      <div className="cuoSectionWrapper cuoTopWrapper">
        {isPreview !== true ? (
          <>
            <div className="cuoSection">
              <Text className="cuoLabel">{cuoNumber}</Text>
              <Text className="cuoValue">{generic?.unitNo}</Text>
            </div>
            <Divider type="vertical" />
          </>
        ) : null}
        <div className="cuoSection">
          <Text className="cuoLabel">{cuoPrice}</Text>
          <Text className="cuoValue">{`${totalPrice()} EUR`}</Text>
        </div>
        <Divider type="vertical" />
        <div className="cuoSection">
          <Text className="cuoLabel">{cuoHfb}</Text>
          <Text className="cuoValue">{detailsTab?.Hfb}</Text>
        </div>
        <Divider type="vertical" />
        <div className="cuoSection">
          <Text className="cuoLabel">{cuoPra}</Text>
          <Text className="cuoValue">{detailsTab?.Pra}</Text>
        </div>
        <Divider type="vertical" />
        <div className="cuoSection">
          <Text className="cuoLabel">{cuoPa}</Text>
          <Text className="cuoValue">{detailsTab?.Pa}</Text>
        </div>
        <Divider type="vertical" />
        <div className="cuoSection">
          <Text className="cuoLabel">{cuoStatus}</Text>
          <Text className="cuoValue">
            {isPreview === true ? detailsTab?.unitState?.key : detailsTab?.unitState}
          </Text>
        </div>
        <Divider type="vertical" />
        <div className="cuoSection">
          <Text className="cuoLabel">{cuoROC}</Text>
          <Text className="cuoValue">
            {isPreview === true ? detailsTab?.roc?.key : detailsTab?.roc}
          </Text>
        </div>
        <Divider type="vertical" />
        <div className="cuoSection">
          <Text className="cuoLabel">{cuoPriceClass}</Text>
          <Text className="cuoValue">
            {isPreview === true ? detailsTab?.priceClass?.key : detailsTab?.priceClass}
          </Text>
        </div>
      </div>
      <div className="cuoSectionWrapper cuoMidWrapper">
        {!isNullEmptyOrUndefined(detailsTab?.PiaLink) ? (
          <div className="cuoSection">
            <Text className="cuoLabel">{cuoReferenceUrl}</Text>
            <div className="cuoValue cuoUrl">
              <a href={getFullUrl(detailsTab?.PiaLink)} target="_blank" rel="noopener noreferrer">
                {detailsTab?.PiaLink}
              </a>
            </div>
          </div>
        ) : null}
        <Accordion className="cuoAccordion">
          {!isNullEmptyOrUndefined(detailsTab?.Comments) ? (
            <AccordionItem id="cuoComments" title={cuoComments}>
              <Text className="cuoComments">{detailsTab?.Comments}</Text>
            </AccordionItem>
          ) : null}
          {detailsTab?.Images?.length > 0 ? (
            <AccordionItem id="cuoImages" title={`${cuoRefImg} (${detailsTab?.Images?.length})`}>
              <RenderImages images={detailsTab?.Images} preview={isPreview} />
            </AccordionItem>
          ) : null}
          <AccordionItem
            id="cuoItems"
            open
            title={`${cuoItems} (${itemsTab?.importedItems?.length > 0 ? itemsTab?.importedItems?.length : 0})`}
          >
            <ChildItemsTable
              data={itemsTab?.importedItems}
              displayTitleBar={false}
              displayLabel={false}
              key="cuoChildItems"
              enableReplace={false}
            />
          </AccordionItem>
        </Accordion>
      </div>
      {isPreview === true ? (
        <div className="cuoBtnWrapper">
          <Button
            className="cuoBtnSubmit"
            type="emphasised"
            text={cuoSubmit}
            onClick={saveUnit}
          />
        </div>
      ) : null}
    </div>
  );
};

CustomNews.defaultProps = {
  generic: undefined,
  isPreview: false,
  saveUnit: undefined,
};

export default CustomNews;
